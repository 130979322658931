<template>
    <CRow class="text-center justify-content-center mx-2" style="width: 24vw;">
      <CCol md="12" class="align-self-center">
        <vue-element-loading
          :active="loading"
          :text="loadingtext"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CRow class="text-center">
          <CCol col="12">
            <CRow class="text-center my-2">
              <CCol class="col-12 my-1">
                <CInput
                  :tabindex="1"
                  block
                  v-model.trim="oldPassword"
                  placeholder="رمز عبور فعلی"
                  :type="showOldPassword"
                  :is-valid="checkKeyboardOldPassword"
                  invalidFeedback="صفحه کلید را انگلیسی کنید!"
                  style="height: 40px"
                >
                  <template #prepend>
                    <CButton
                      style="box-shadow: none; background-color: #f0f3f5"
                    >
                      <CIcon name="cil-lock-locked" />
                    </CButton>
                  </template>
                  <template #append>
                    <CButton
                      style="box-shadow: none; background-color: #f0f3f5"
                      @click="showPass"
                    >
                      <div v-if="showOldPassword == 'text'">
                        <font-awesome-icon icon="eye" />
                      </div>
                      <div v-else>
                        <font-awesome-icon icon="eye-slash" />
                      </div>
                    </CButton>
                  </template>
                </CInput>
              </CCol>
              <CCol class="col-12 my-1">
                <CInput
                  :tabindex="2"
                  block
                  v-model.trim="newPassword"
                  placeholder="رمز عبور جدید"
                  :type="showPassword"
                  :is-valid="checkKeyboardNewPassword"
                  invalidFeedback="صفحه کلید را انگلیسی کنید!"
                  style="height: 40px"
                >
                  <template #prepend>
                    <CButton
                      style="box-shadow: none; background-color: #f0f3f5"
                    >
                      <CIcon name="cil-lock-locked" />
                    </CButton>
                  </template>
                  <template #append>
                    <CButton
                      style="box-shadow: none; background-color: #f0f3f5"
                      @click="showPass"
                    >
                      <div v-if="showPassword == 'text'">
                        <font-awesome-icon icon="eye" />
                      </div>
                      <div v-else>
                        <font-awesome-icon icon="eye-slash" />
                      </div>
                    </CButton>
                  </template>
                </CInput>
              </CCol>
              <CCol class="col-12 my-1">
                <CInput
                  :tabindex="3"
                  block
                  v-model.trim="confirmPassword"
                  placeholder="تکرار رمز عبور جدید"
                  :type="showPasswordConfirm"
                  invalidFeedback="صفحه کلید را انگلیسی کنید!"
                  style="height: 40px"
                >
                  <template #prepend>
                    <CButton
                      style="box-shadow: none; background-color: #f0f3f5"
                    >
                      <CIcon name="cil-lock-locked" />
                    </CButton>
                  </template>
                  <template #append>
                    <CButton
                      style="box-shadow: none; background-color: #f0f3f5"
                      @click="showPass"
                    >
                      <div v-if="showPasswordConfirm == 'text'">
                        <font-awesome-icon icon="eye" />
                      </div>
                      <div v-else>
                        <font-awesome-icon icon="eye-slash" />
                      </div>
                    </CButton>
                  </template>
                </CInput>
              </CCol>
            </CRow>

            <CRow class="text-center my-3 justify-content-center">
              <CCol class="col-8">
                <CButton
                  :tabindex="4"
                  block
                  @click="updateAndLogin"
                  @keyup.enter="updateAndLogin"
                  style="background-color: cadetblue; color: white"
                  >ویرایش و ورود</CButton
                >
              </CCol>
              <CCol class="col-1"></CCol>
              <CCol class="col-3">
                <CButton
                  style="background-color: red; color: white"
                  block
                  @click="
                    () => {
                      $emit('close');
                    }
                  "
                  >لغو</CButton
                >
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
</template>

<script>
import { mapState, mapActions } from "vuex";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { detectKeyboardLanguageService } from "../../services/detectKeyboardLanguage.service";

export default {
  name: "ChangePassword",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
  },
  data: () => ({
    loading: false,
    loadingtext: "در حال ارسال اطلاعات ...",

    showOldPassword: "password",
    showPassword: "password",
    showPasswordConfirm: "password",

    oldPassword: "",
    newPassword: "",
    confirmPassword: "",

    showBanner: false,
  }),
  validations() {
    return {
      oldPassword: {
        required,
      },
      newPassword: {
        required,
      },
      confirmPassword: {
        required,
      },
    };
  },
  methods: {
    ...mapActions("login", ["currentUserChangePassword", "logout"]),
    showPass() {
      if (this.showPassword === "password") {
        this.showPassword = "text";
      } else {
        this.showPassword = "password";
      }
      if (this.showPasswordConfirm === "password") {
        this.showPasswordConfirm = "text";
      } else {
        this.showPasswordConfirm = "password";
      }
      if (this.showOldPassword === "password") {
        this.showOldPassword = "text";
      } else {
        this.showOldPassword = "password";
      }
    },
    checkKeyboardOldPassword() {
      const isEng = detectKeyboardLanguageService.detectLanguage(
        this.oldPassword.trim()
      );
      return isEng;
    },
    checkKeyboardNewPassword() {
      const isEng = detectKeyboardLanguageService.detectLanguage(
        this.newPassword.trim()
      );
      return isEng;
    },
    async updateAndLogin() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.$notify({
          title: "پیام سیستم ",
          text: "پارامترهای ورودی را بررسی نمایید <br /><br />",
          type: "error",
        });
        return;
      }
      this.loading = true;
      const { oldPassword, newPassword } = this;
      let loginState = await this.currentUserChangePassword({
        oldPassword,
        newPassword,
      });
      this.loading = false;
      if (loginState.succeeded) {
        this.$notify({
          title: "پیام سیستم ",
          text: ` ${loginState.message} <br /><br />`,
          type: "success",
        });
        this.logout();
      } else {
        this.$notify({
          title: "پیام سیستم ",
          text: ` ${loginState.message} <br /><br />`,
          type: "error",
        });
      }
    },
    goLogin() {
      this.logout();
    },
  },
  watch: {},
  computed: mapState({
    classObject: (state) => state.theme.darkMode,
  }),
};
</script>

<style>


</style>

<style scoped>
.userType {
  border: 1px solid #bbb;
  border-radius: 7px;
  float: right;
  margin: 0px 13px 0px 3px;
  width: 56px;
  padding-top: 4px;
  cursor: pointer;
}
.userType:hover {
  box-shadow: 0px 0px 10px 1px #6fd820;
}

.userTypeDeSelect h6 {
  background-color: #f80;
}
.userTypeSelect h6 {
  background-color: #6fd820;
}

.userType h6 {
  height: 30px;
  margin: 0px;
  padding-top: 6px;
  border-radius: 0px 0px 6px 6px;
  color: #fff;
}
.userType .img {
  color: #f80;
}

.bgLogin {
  background-image: url("/img/bgLogIn.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #7c47aa;
}
.bgLogo {
  background-image: url("/img/saharaLogo.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.rowHeight {
  height: 100vh;
}
html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}
.row {
  margin-left: 0%;
  margin-right: 0%;
}
.form-group {
  margin-bottom: 0 !important;
}

.resetPassword {
  text-align: center;
  color: white;
  background-color: #0b7bd5;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: 92%;
  height: 32px;
  padding: 9px 5% 2px 5%;
  margin-right: 4%;
  border-radius: 3px;
  cursor: pointer;
}
</style>

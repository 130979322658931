<template>
  <div class="main-box">
    <div class="info-box">
      <div class="info-item" v-for="(item, index) of partnerList" :key="index">
        <span>{{ item.typeName }}</span>
        <ul>
          <li>
            <h5>نام و نام خانوادگی</h5>
            <h6>{{ item.fullName }}</h6>
          </li>
          <li>
            <h5>شماره همراه</h5>
            <h6>{{ item.phone }}</h6>
          </li>
          <li>
            <h5>تعداد زمین</h5>
            <h6>{{ item.farmCount }}</h6>
          </li>
          <li>
            <h5>آدرس</h5>
            <h6>
              {{ item.address }}
            </h6>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getCurrentUser } from "../utils/index";
export default {
  name: "userPartner",
  data() {
    return {
      loading: false,
      partnerList: [],
    };
  },
  methods: {
    ...mapActions("dashboard", ["GetAdvicerPartner", "GetFarmUserPartner"]),
    async fetchData(usertype) {
      switch (usertype) {
        case "FarmUser": {
          this.loading = true;
          let resultFarmUser = await this.GetFarmUserPartner({count : 5});
          this.loading = false;
          if (resultFarmUser.succeeded) {
            this.partnerList = resultFarmUser.data;
          } else {
            this.$notify({
              title: "پیام سیستم",
              text: `${resultFarmUser.message}  <br /><br />`,
              type: "error",
            });
          }
          break;
        }
        case "Advicer": {
          this.loading = true;
          let resultAdvicer = await this.GetAdvicerPartner({count : 5});
          this.loading = false;
          if (resultAdvicer.succeeded) {
            this.partnerList = resultAdvicer.data;
          } else {
            this.$notify({
              title: "پیام سیستم",
              text: `${resultAdvicer.message}  <br /><br />`,
              type: "error",
            });
          }
          break;
        }
        default:
          this.$notify({
            title: "پیام سیستم",
            text: "نوع کاربری شما معتبر نمی باشد<br /><br />",
            type: "error",
          });
          break;
      }
    },
  },
  mounted() {
    this.fetchData(getCurrentUser().type);
  },
};
</script>

<style scoped>
.main-box {
  width: 40vw;
  height: 40vh;
}

.info-box {
  float: right;
  width: 100%;
  height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.info-item {
  float: right;
  position: relative;
  width: 95%;
  height: 6vh;
  margin: 3vh 1vw 2vh 0px;
  background-color: #e3e3e3;
  border-radius: 30px 0px 0px 30px;
}
.info-item span {
  float: right;
  width: 5vw;
  height: 9vh;
  background-color: #2eb85c;
  text-align: center;
  padding-top: 3vh;
  color: #fff;
  border-radius: 50px;
  position: absolute;
  top: -1em;
  right: -1em;
  font-size: 1em;
}
.info-item ul {
  float: right;
  margin: 0px;
  padding-right: 4vw;
  list-style: none;
}
.info-item ul li {
  float: right;
  margin: 4px 1vw;
}
.info-item ul li h5 {
  float: right;
  font-size: 0.9em;
  color: darkgreen;
  margin: 0px 0px;
  font-weight: bold;
}
.info-item ul li h6 {
  float: right;
  margin: 1px 7px;
  font-size: 0.9em;
}
</style>
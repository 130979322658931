<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="d-flex mx-4 text-nowrap align-items-center" style="gap: 6px; min-height: 55px">
          <span class="d-none d-md-inline-block" style="font-size: 13px">{{ LoginedFullName }}</span>
          <span class="d-none d-md-inline-block" style="font-size: 13px">({{ userTypeName }})</span>
          <div class="position-relative c-avatar">
            <img src="/img/avatars/farmer.png" class="c-avatar-img border rounded-circle shadow" alt="farmer"/>
            <span class="dot-online"></span>
          </div>
        </div>
      </CHeaderNavLink>
    </template>
    <div class="py-2">
      <CDropdownItem @click="editProfile">
        <CIcon name="cil-address-book" class="mx-2"/>
        ویرایش اطلاعات
      </CDropdownItem>
      <hr>
      <CDropdownItem @click="changePassword">
        <CIcon name="cil-lock-locked" class="mx-2"/>
        ویرایش رمز عبور
      </CDropdownItem>
      <CDropdownItem @click="doLogOut">
        <CIcon name="cil-exit-to-app" class="mx-2"/>
        خروج
      </CDropdownItem>
    </div>
  </CDropdown>
</template>

<script>

import {mapActions, mapState} from "vuex";
import {getCurrentUser} from "../utils";

export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
      logedInUser: "",
      userTypeName: "",
    };
  },
  computed: {
    ...mapState({
      getUserType: (state) => state.register.userType,
      LoginedFullName: (state) =>
        state.login.type == null
          ? state.register.fullName == ""
            ? state.register.username
            : state.register.fullName
          : state.login.fullName == ""
            ? state.login.username
            : state.login.fullName,
    })
  },
  methods: {
    ...mapActions("login", ["logout"]),
    doLogOut() {
      this.logout();
    },
    changePassword() {
      this.$root.$refs.Container.changeComponent("ChangePassword");
    },
    editProfile() {
      this.$root.$refs.Container.changeComponent("EditProfile");
    },
  },
  mounted() {
    let logedInUser = getCurrentUser();
    this.logedInUser = logedInUser.type;
    this.userTypeName = getCurrentUser().moreInfo.typeName;
  }
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>

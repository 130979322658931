<template>
    <CRow class="text-center"   style="width: 40vw;">
      <CCol col="12">
        <vue-element-loading
          :active="loading"
          :text="loadingtext"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.firstName.$model"
              placeholder="نام"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.firstName.$error"
              v-text="
                $v.firstName.onlyChar
                  ? 'نام خود را وارد کنید'
                  : 'تنها مجاز به وارد کردن حروف فارسی هستید'
              "
            ></label>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.lastName.$model"
              placeholder="نام خانوادگی"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.lastName.$error"
              v-text="
                $v.lastName.onlyChar
                  ? 'نام خانوادگی خود را وارد کنید'
                  : 'تنها مجاز به وارد کردن حروف فارسی هستید'
              "
            ></label>
          </CCol>
        </CRow>
        <br />
        <CRow class="mt-2">
          <CCol class="col-12" style="color: #ccc">
            <v-select
              block
              dir="rtl"
              v-model="country"
              :options="countriesSource"
              placeholder="انتخاب کشور..."
            ></v-select>
            <label
              class="error"
              v-if="$v.country.$error"
              v-text="'نام کشور را انتخاب کنید'"
            ></label>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol class="col-12" style="color: #ccc">
            <v-select
              block
              dir="rtl"
              v-model="state"
              :options="statesSource"
              placeholder="انتخاب استان..."
            ></v-select>
            <label
              class="error"
              v-if="$v.state.$error"
              v-text="'نام استان را انتخاب کنید'"
            ></label>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol class="col-12" style="color: #ccc">
            <v-select
              block
              dir="rtl"
              v-model="city"
              :options="cityOptions"
              placeholder="انتخاب شهر..."
            ></v-select>
            <label
              class="error"
              v-if="$v.city.$error"
              v-text="'نام شهر را انتخاب کنید'"
            ></label>
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.companyName.$model"
              placeholder="نام شرکت"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.companyName.$error"
              v-text="'نام شرکت را وارد کنید'"
            ></label>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.companyAddress.$model"
              placeholder="آدرس شرکت"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.companyAddress.$error"
              v-text="'آدرس شرکت را وارد کنید'"
            ></label>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.companyPhone.$model"
              placeholder="تلفن شرکت"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.companyPhone.$error"
              v-text="'تلفن شرکت را وارد کنید'"
            ></label>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.companyPostcode.$model"
              placeholder="کد پستی شرکت"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.companyPostcode.$error"
              v-text="'تنها مجاز به وارد کردن عددهستید'"
            ></label>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.companyEconomicCode.$model"
              placeholder="کد اقتصادی شرکت"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.companyEconomicCode.$error"
              v-text="'تنها مجاز به وارد کردن عددهستید'"
            ></label>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.companyNationalId.$model"
              placeholder="شناسه ملی شرکت"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.companyNationalId.$error"
              v-text="'تنها مجاز به وارد کردن عددهستید'"
            ></label>
          </CCol>
        </CRow>
        <br />
        <CRow>
         <CCol class="col-9">
            <CButton
              style="background-color: #0d722f; color: white"
              block
              @click="editProfile"
              >ذخیره تغییرات</CButton
            >
          </CCol>
          <CCol class="col-1"></CCol>
          <CCol class="col-2">
            <CButton
              style="background-color: red; color: white"
              block
              @click="()=>{$emit('close')}"
              >لغو</CButton
            >
          </CCol>
        </CRow>
      </CCol>
    </CRow>
</template>

<script>
import { mapActions } from "vuex";

import { required,  helpers } from "vuelidate/lib/validators";
const onlyChar = helpers.regex("onlyChar", /^[ _آ-یءچ]+$/);
const onlyNum = helpers.regex("onlyNum", /^[0-9]/);
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "legalEntityProfile",
  components: {
    vSelect,
  },
  data: () => ({
    loading: false,
    loadingtext: "در حال ارسال اطلاعات",

    firstName: "",
    lastName: "",
    countriesSource: [],
    country: "",
    statesSource: [],
    state: "",
    cityOptions: [],
    city: "",
    companyName: "",
    companyAddress: "",
    companyPhone: "",
    companyPostcode: "",
    companyEconomicCode: "",
    companyNationalId: "",
  }),
  watch: {
    country: function () {
      this.statesSource = [];
      this.state = "";
      this.cityOptions = [];
      this.city = "";
      if (this.country.id > 0) {
        this.GetStateWithCountryId(this.country.id , null);
      }
    },
    state: function () {
      this.cityOptions = [];
      this.city = "";
      if (this.state.id > 0) {
        this.GetCityWithStateId(this.state.id , null);
      }
    },
  },
  validations: {
    firstName: {
      required,
      onlyChar,
    },
    lastName: {
      required,
      onlyChar,
    },
    country: { required },
    state: { required },
    city: { required },
    companyName: { required },
    companyAddress: { required },
    companyPhone: { required },
    companyPostcode: { onlyNum },
    companyEconomicCode: { onlyNum },
    companyNationalId: { onlyNum },
  },
  methods: {
    ...mapActions("dashboard", [
      "updateLegalEntity",
      "GetLegalEntityProfile",
      "GetAllCountry",
      "GetStateWithCountry",
      "GetCityWithState",
    ]),

    async editProfile() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          title: "پیام سیستم",
          text: "قسمت های ستاره دار را تکمیل کنید <br /><br />",
          type: "info",
        });
        return;
      } else {
        this.loading = true;
        this.loadingtext = "در حال ذخیره تغییرات ...";
        let legalEntity = {
          firstName: this.firstName,
          lastName: this.lastName,
          stateId : this.state.id,
          cityId : this.city.id,
          companyName : this.companyName,
          companyAddress : this.companyAddress,
          companyPhone : this.companyPhone,
          companyPostcode  : this.companyPostcode,
          companyEconomicCode : this.companyEconomicCode,
          companyNationalId : this.companyNationalId
        };
        let result = await this.updateLegalEntity({ legalEntity: legalEntity });
        this.loading = false;
        this.$emit("close");
        if (result) {
          this.$notify({
            title: "پیام سیستم",
            text: "تغییرات ذخیره شد.",
            type: "success",
          });
        } else {
          this.$notify({
            title: "خطا ثبت نام",
            text: "اطلاعات ارسالی معتبر نیست <br /><br />",
            type: "error",
          });
        }
      }
    },
    async fetchData() {
      this.loading = true;
      await this.getAllCountry();
      var result = await this.GetLegalEntityProfile({});
      if (result) {
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        this.companyName = result.companyName;
        this.companyAddress = result.companyAddress;
        this.companyPhone = result.companyPhone;
        this.companyPostcode = result.companyPostcode;
        this.companyEconomicCode = result.companyEconomicCode;
        this.companyNationalId = result.companyNationalId;
        this.country = this.countriesSource.filter((item) => {
          return item.id == result.countryId;
        })[0];
        await this.GetStateWithCountryId(result.countryId,result.stateId);
        await this.GetCityWithStateId(result.stateId,result.cityId);
        this.loading = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات",
          type: "error",
        });
      }
    },

    async getAllCountry() {
      var result = await this.GetAllCountry({ pageNumber: 0, pageSize: 0 });
      this.countriesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async GetStateWithCountryId(countryId, stateId) {
      var result = await this.GetStateWithCountry({ countryId: countryId });
      this.statesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
      if (stateId) {
        this.state = this.statesSource.filter((item) => {
          return item.id == stateId;
        })[0];
      }
    },
    async GetCityWithStateId(stateId, cityId) {
      var result = await this.GetCityWithState({ stateId: stateId });
      this.cityOptions = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
      if (cityId) {
        this.city = this.cityOptions.filter((item) => {
          return item.id == cityId;
        })[0];
      }
    },
  },
  computed: {},
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.bgLogin {
  background-image: url("/img/bgLogIn.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #7c47aa;
}
.bgLogo {
  background-image: url("/img/saharaLogo.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.rowHeight {
  height: 100vh;
}
html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}
.row {
  margin-left: 0%;
  margin-right: 0%;
}
.radioBox {
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 5px 0px #bbb;
  box-shadow: 0px 0px 5px 0px #bbb;
  padding: 4px;
  width: 93%;
  margin-right: 16px !important;
  border-radius: 4px;
  height: 32px;
}
.verifyInput {
  width: 40px;
  height: 30px;
  float: left;
  box-shadow: 0px 0px 2px 1px #bbb;
  border: none;
  text-align: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.insertInput {
  float: right;
  width: 100%;
  border: none;
  height: 35px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px #bbb;
  margin: 3px 0px 3px 0px;
  padding-right: 5px;
  outline: none;
}
.insertInputRequired {
  float: right;
  color: red;
  position: absolute;
  right: 17px;
  top: 0px;
}
.error {
  position: absolute;
  top: 9%;
  left: 15px;
  color: #ff00009c;
}
</style>

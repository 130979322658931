<template>
  <div class="main-form">
    <vue-element-loading
      :active="loadingData"
      text="در حال دریافت اطلاعات ..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="row">
      <div class="col-md-12 send-box">
        <h5>کاربر کارمند مزرعه</h5>
        <input type="text" readonly v-model="codeIdentity" />
        <h5>شماره همراه کارفرما</h5>
        <input
          type="text"
          placeholder="09 ---------"
          v-model="farmerPhone"
          @keypress="isNumber($event)"
        />
        <h5>نوع کارفرما</h5>
        <select name="" id="" v-model="userType">
          <option value="null" selected disabled>
            نوع کارفرما را انتخاب کنید
          </option>
          <option value="Farmer">کشاورز</option>
          <option value="LegalEntity">سازمان حقوقی</option>
        </select>
        <button class="learn-more" @click="sendCodeSMS()">
          ارسال پیامک
          <i class="fa fa-mobile" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getCurrentUser } from "../utils/index";

export default {
  name: "theHeaderSendIdentityCode",
  data() {
    return {
      loadingData: false,
      codeIdentity: "کد کارمند دریافت نشد",
      modalShow: false,
      farmerPhone: null,
      loadingsendMessage: false,
      userType: null,
    };
  },
  methods: {
    ...mapActions("dashboard", [
      "GetUserInfoWithPhone",
      "GetUserIdentityCode",
      "SendUserIdentityCode",
    ]),
    async getProfileInfo() {
      this.loadingData = true;
      let result = await this.GetUserIdentityCode({
        userType: getCurrentUser().type,
      });
      this.loadingData = false;
      if (result.succeeded) {
        this.codeIdentity = result.data.codeIdentity;
      } else {
        this.codeIdentity = "کد کارمند دریافت نشد";
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async sendCodeSMS() {
      this.loadingData = true;
      let result = await this.GetUserInfoWithPhone({
        phone: this.farmerPhone,
        userType: this.userType,
      });
      this.loadingData = false;
      this.$confirm({
        message: `کد برای ${result.fullName} با نوع کاربری ${result.type} ارسال شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.formLoading = true;
            this.formLoadingText = "در حال حذف اطلاعات نماینده.";
            let sendCodeResult = await this.SendUserIdentityCode({
              phone: this.farmerPhone,
            });
            this.formLoading = false;
            if (sendCodeResult.state) {
              this.$notify({
                title: "انجام شد",
                text: "کد شما به کارفرما ارسال شد<br /><br />",
                type: "success",
              });
            } else {
              this.$notify({
                title: "خطا",
                text: `${sendCodeResult.message} <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
  },
  mounted() {
    this.getProfileInfo();
  },
};
</script>
<style scoped>
.main-form {
  width: 25vw;
  margin-top: 1vh;
  height: 30vh;
}
.send-box {
  float: right;
  clear: both;
  background-color: #ffff;
  height: 30vh;
  padding: 1em;
}
.send-box h5 {
  float: right;
  clear: both;
  color: #c77700;
  font-size: 1em;
  margin-top: 1%;
  margin-bottom: 0px;
  font-weight: bolder;
}
.send-box input[type="text"] {
  float: right;
  clear: both;
  width: 100%;
  border: none;
  border-bottom: 1px solid #bbb;
  margin-bottom: 5%;
  outline: none;
  direction: ltr;
}
.send-box input[type="text"]:read-only {
  background-color: #f5f5f5;
  border: none;
}
.send-box button {
  clear: both;
  float: right;
  width: 8vw;
  height: 4vh;
  border-radius: 19px;
  border: none;
  color: #fff;
  background-color: #c77700;
  text-align: center;
  font-size: 1.2em;
  box-shadow: 0px 13px 12px -10px #000000;
  margin-top: 2em;
  padding-top: 6px;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
}
.send-box button:hover {
  background-color: #00c74c;
}
.send-box button i {
  float: right;
  font-size: 2em;
  margin: 4px 9px;
}
.send-box select {
  float: right;
  clear: both;
  width: 100%;
  border: none;
  border-bottom: 1px solid #bbb;
  outline: none;
}
/*.send-box button {
  clear: both;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  top: 1em;
  right: 6vw;
}
 .send-box button.learn-more {
  width: 12rem;
  height: auto;
}
.send-box button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}
.send-box button.learn-more .circle i {
  position: absolute;
  right: 1rem;
  top: 6px;
  bottom: 0;
  margin: auto;
  color: #fff;
  font-size: 33px;
}
.send-box button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 1.85rem 0 0;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
.send-box button:hover .circle {
  width: 100%;
}
.send-box button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(-1em, 0);
}
.send-box button:hover .button-text {
  color: #fff;
} */
</style>

<template>
    <CRow class="text-center"  style="width: 40vw">
      <CCol col="12">
        <vue-element-loading
          :active="loading"
          :text="loadingtext"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.firstName.$model"
              placeholder="نام"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.firstName.$error"
              v-text="
                $v.firstName.onlyChar
                  ? 'نام خود را وارد کنید'
                  : 'تنها مجاز به وارد کردن حروف فارسی هستید'
              "
            ></label>
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol class="col-12">
            <span class="insertInputRequired">*</span>
            <input
              type="text"
              v-model="$v.lastName.$model"
              placeholder="نام خانوادگی"
              class="insertInput"
            />
            <label
              class="error"
              v-if="$v.lastName.$error"
              v-text="
                $v.lastName.onlyChar
                  ? 'نام خانوادگی خود را وارد کنید'
                  : 'تنها مجاز به وارد کردن حروف فارسی هستید'
              "
            ></label>
          </CCol>
        </CRow>
        <br />
        <CRow class="mt-2">
          <CCol class="col-12" style="color: #ccc">
            <v-select
              block
              dir="rtl"
              v-model="state"
              :options="statesSource"
              placeholder="انتخاب استان..."
            ></v-select>
            <label
              class="error"
              v-if="$v.state.$error"
              v-text="'نام استان را انتخاب کنید'"
            ></label>
          </CCol>
        </CRow>
        <br />
        <CRow class="mt-2">
          <CCol class="col-12" style="color: #ccc">
            <v-select
              block
              dir="rtl"
              v-model="city"
              :options="cityOptions"
              placeholder="انتخاب شهر..."
            ></v-select>
            <label
              class="error"
              v-if="$v.city.$error"
              v-text="'نام شهر را انتخاب کنید'"
            ></label>
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol class="col-12">
            <input
              type="text"
              v-model="tel"
              placeholder="تلفن ثابت"
              class="insertInput"
            />
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol class="col-12">
            <input
              type="text"
              v-model="address"
              placeholder="آدرس"
              class="insertInput"
            />
          </CCol>
        </CRow>
        <br />
        <CRow class="mt-2">
          <CCol class="col-12" style="color: #ccc">
            <v-select
              block
              dir="rtl"
              v-model="education"
              :options="educationOptions"
              placeholder="انتخاب تحصیلات..."
            ></v-select>
          </CCol>
        </CRow>
        <br />
        <br />
        <br />
        <CRow>
          <CCol class="col-9">
            <CButton
              style="background-color: #0d722f; color: white"
              block
              @click="editProfile"
              >ذخیره تغییرات</CButton
            >
          </CCol>
          <CCol class="col-1"></CCol>
          <CCol class="col-2">
            <CButton
              style="background-color: red; color: white"
              block
              @click="
                () => {
                  $emit('close');
                }
              "
              >لغو</CButton
            >
          </CCol>
        </CRow>
      </CCol>
    </CRow>
</template>

<script>
import { mapActions } from "vuex";

import { required, helpers } from "vuelidate/lib/validators";
const onlyChar = helpers.regex("onlyChar", /^[ _آ-یءچ]+$/);
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "farmUserProfile",
  components: {
    vSelect,
  },
  data: () => ({
    loading: false,
    loadingtext: "در حال ارسال اطلاعات",
    firstName: "",
    lastName: "",
    tel: "",
    address: "",
    education: "",
    educationOptions: [
      {
        value: "DownCycle",
        label: "زیر سیکل",
      },
      {
        value: "Cycle",
        label: "سیکل",
      },
      {
        value: "Diploma",
        label: "دیپلم",
      },
      {
        value: "AssociateDegree",
        label: "فوق دیپلم",
      },
      {
        value: "Expert",
        label: "کارشناسی",
      },
      {
        value: "Masters",
        label: "کارشناسی ارشد",
      },
      {
        value: "Doctorate",
        label: "دکترا",
      },
      {
        value: "UperDoctorate",
        label: "بالاتر از دکتر",
      },
    ],
    statesSource: [],
    state: "",
    cityOptions: [],
    city: "",
  }),
  watch: {
    state: function () {
      this.cityOptions = [];
      this.city = "";
      if (this.state.id > 0) {
        this.GetCityWithStateId(this.state.id, null);
      }
    },
  },
  validations: {
    firstName: {
      required,
      onlyChar,
    },
    lastName: {
      required,
      onlyChar,
    },
    state: {
      required,
    },
    city: {
      required,
    },
  },
  methods: {
    ...mapActions("dashboard", [
      "updateFarmUser",
      "GetFarmUserProfile",
      "GetStateWithCountry",
      "GetCityWithState",
    ]),

    async editProfile() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          group: "auth",
          title: "پیام سیستم",
          text: "قسمت های ستاره دار را تکمیل کنید <br /><br />",
          type: "info",
        });
        return;
      } else {
        this.loading = true;
        this.loadingtext = "در حال ذخیره تغییرات ...";
        let farmuser = {
          firstName: this.firstName,
          lastName: this.lastName,
          stateId : this.state.id,
          cityId : this.city.id,
          tel: this.tel,
          address: this.address,
          education: this.education.value,
        };
        let result = await this.updateFarmUser({ farmUser: farmuser });
        this.loading = false;
        this.$emit("close");
        if (result) {
          this.$notify({
            title: "پیام سیستم",
            text: "تغییرات ذخیره شد.",
            type: "success",
          });
        } else {
          this.$notify({
            title: "خطا ثبت نام",
            text: "اطلاعات ارسالی معتبر نیست <br /><br />",
            type: "error",
          });
        }
      }
    },
    async fetchData() {
      this.loading = true;
      var result = await this.GetFarmUserProfile({});
      if (result) {
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        this.tel = result.tel;
        this.address = result.address;
        this.education = this.educationOptions.filter((item) => {
          return item.value == result.education;
        })[0];
        await this.GetStateWithCountryId(result.countryId, result.stateId);
        await this.GetCityWithStateId(result.stateId, result.cityId);
        this.loading = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات",
          type: "error",
        });
      }
    },
    async GetStateWithCountryId(countryId, stateId) {
      var result = await this.GetStateWithCountry({ countryId: countryId });
      this.statesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
      if (stateId) {
        this.state = this.statesSource.filter((item) => {
          return item.id == stateId;
        })[0];
      }
    },
    async GetCityWithStateId(stateId, cityId) {
      var result = await this.GetCityWithState({ stateId: stateId });
      this.cityOptions = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
      if (cityId) {
        this.city = this.cityOptions.filter((item) => {
          return item.id == cityId;
        })[0];
      }
    },
  },
  computed: {},
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.bgLogin {
  background-image: url("/img/bgLogIn.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #7c47aa;
}
.bgLogo {
  background-image: url("/img/saharaLogo.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.rowHeight {
  height: 100vh;
}
html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}
.row {
  margin-left: 0%;
  margin-right: 0%;
}
.radioBox {
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 5px 0px #bbb;
  box-shadow: 0px 0px 5px 0px #bbb;
  padding: 4px;
  width: 93%;
  margin-right: 16px !important;
  border-radius: 4px;
  height: 32px;
}
.verifyInput {
  width: 40px;
  height: 30px;
  float: left;
  box-shadow: 0px 0px 2px 1px #bbb;
  border: none;
  text-align: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.insertInput {
  float: right;
  width: 100%;
  border: none;
  height: 35px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px #bbb;
  margin: 3px 0px 3px 0px;
  padding-right: 5px;
  outline: none;
}
.insertInputRequired {
  float: right;
  color: red;
  position: absolute;
  right: 17px;
  top: 0px;
}
.error {
  position: absolute;
  top: 9%;
  left: 15px;
  color: #ff00009c;
}
</style>

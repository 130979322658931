<template>
  <CHeader class="flex items-center" with-subheader style="height: 6dvh">
    <CToggler in-header class="ml-3 d-lg-none" @click="dotoggleSidebarMobile" />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="dotoggleSidebarDesktop"
    />

    <div
      v-if="['Advicer', 'FarmUser'].includes(this.userTypeName)"
      class="flex-center"
      style="list-style: none"
    >
      <CDropdown
        v-if="expertiseState"
        color="primary"
        size="sm"
        class="mr-5"
        toggler-text="ابزار تخصصی"
      >
        <CDropdownItem class="y-center-g-10" @click="openSendCodeModal">
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
          ارسال کد برای همکاری
        </CDropdownItem>
        <CDropdownItem class="y-center-g-10" @click="userPartnerForm()">
          <i class="fa fa-users" aria-hidden="true"></i>
          همکاران جدید
        </CDropdownItem>
        <CDropdownItem
          class="y-center-g-10"
          v-if="this.userTypeName === 'Advicer'"
          @click="$router.push('userExpertiseForm')"
        >
          <i class="fa fa-address-card-o" aria-hidden="true"></i>
          پروفایل تخصصی
        </CDropdownItem>
      </CDropdown>
      <div v-else>
        <CAlert class="expertiseState m-0">
          حساب کاربری شما فعال نیست برای فعال سازی اطلاعات حساب کاربری خود را
          تکمیل کنید
          <CButton color="danger" size="sm" @click="userExpertiesForm()">
            <i class="fa fa-address-card-o" aria-hidden="true"></i>
            تکمیل اطلاعات حساب
          </CButton>
        </CAlert>
      </div>
    </div>

    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <div class="row align-items-start">
        <div class="col">
          <img
            class="c-sidebar-brand-minimized"
            src="/img/banner.png"
            alt="شرکت صحرا شرق کیمیا"
            style="width: 13rem"
          />
        </div>
      </div>
    </CHeaderBrand>

    <CHeaderNav style="position: absolute; left: 1%; top: 0">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>

    <VueModal
      title="ارسال کد برای همکاری با کشاورز یا سازمان حقوقی"
      v-model="userSendCodeModelState"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      :enable-close="true"
    >
      <TheHeaderFarmUserInfo v-if="userSendCodeModelState" />
    </VueModal>
    <VueModal
      title="لیست همکاران جدید شما"
      v-model="userPartnerModelState"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      :enable-close="true"
    >
      <UserPartner v-if="userPartnerModelState" />
    </VueModal>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import TheHeaderFarmUserInfo from "./TheHeaderFarmUserInfo";
import { mapMutations, mapActions } from "vuex";
import { getCurrentUser } from "../utils/index";
import UserPartner from "./UserPartner";

export default {
  name: "TheHeader",
  data() {
    return {
      userTypeName: "",
      expertiseState: null,
      title: "",
      code: "",
      inventory: 0,
      inventoryState: true,
      inventoryMessage: "",
      userCount: {
        farmCount: 0,
        legalEntityCount: 0,
        agentCount: 0,
        farmerCount: 0,
        farmUserCount: 0,
        advicerCount: 0,
      },
      userSendCodeModelState: false,
      userPartnerModelState: false,
    };
  },
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderFarmUserInfo,
    UserPartner,
  },
  methods: {
    ...mapActions("financialUser", ["UserInventory"]),
    ...mapMutations(["theme"]),
    openSendCodeModal() {
      this.userSendCodeModelState = true;
    },
    dotoggleSidebarDesktop() {
      this.$store.commit("theme/toggleSidebarDesktop");
    },
    dotoggleSidebarMobile() {
      this.$store.commit("theme/toggleSidebarMobile");
    },
    dotoggledarkMode() {
      this.$store.commit("theme/toggle", "darkMode");
    },
    dotoggleasideShow() {
      this.$store.commit("theme/toggle", "asideShow");
    },
    async getCurrentUserFinancialAccountInventory() {
      let result = await this.UserInventory({});
      this.inventoryState = result.succeeded;
      this.inventoryMessage = result.message;
      if (result.succeeded) {
        this.inventory = result.data.inventory;
      }
    },
    userPartnerForm() {
      this.userPartnerModelState = true;
    },
  },
  mounted() {
    this.userTypeName = getCurrentUser().type;
    this.title = getCurrentUser().moreInfo.typeName;
    this.code = getCurrentUser().moreInfo.code;
    if (this.userTypeName == "Advicer")
      this.expertiseState = getCurrentUser().moreInfo.expertiseState;
    if (this.userTypeName != "FarmUser")
      this.getCurrentUserFinancialAccountInventory();
  },
};
</script>
<style scoped>
.countBox {
  width: 15%;
  padding-left: 5px;
  box-shadow: 0 0 3px 0 #000;
  margin-left: 16px;
  height: 20px;
  padding-right: 4px;
  padding-top: 2px;
  border-radius: 30px;
}

.countBox h5 {
  text-align: center;
  padding-top: 1px;
  color: #f80;
  font-size: 1.1em;
}

.headerBTN {
  margin: 14px 5px;
  color: #fff;
  width: 10vw;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(
    to right,
    #667eea,
    #764ba2,
    #6b8dd6,
    #8e37d7
  );
  box-shadow: 0 2px 4px 0 rgba(116, 79, 168, 0.75);
}

.headerBTN:hover {
  background-position: 100% 0;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.headerBTN i {
  margin: 3px 4px;
}

.sendCodeBTN {
  border: none;
  margin: 15px 5px;
  background-color: #ff9d0a;
  color: #fff;
  border-radius: 7px;
  width: 10vw;
}

.sendCodeBTN i {
  margin: 2px;
}

.sendCodeBTN:hover {
  box-shadow: 0 2px 5px 1px #000;
}

@keyframes anim-popoutin {
  0% {
    color: red;
  }

  50% {
    color: black;
    text-shadow: 0 0 3px red;
  }

  100% {
    color: red;
  }
}
</style>

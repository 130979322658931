<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="doSetsidebarShow"
  >
    <div class="imageCover"></div>
      <CSidebarBrand class="d-md-down-none p-5" to="/">
        <div class="row align-items-start">
          <div class="col">
            <img
              class="c-sidebar-brand-full"
              src="../assets/img/banner.png"
              alt="شرکت صحرا شرق کیمیا"
              style="width: 13rem"
            />
          </div>
        </div>

        <div class="row align-items-start">
          <div class="col">
            <img
              class="c-sidebar-brand-minimized"
              src="/favicon-96x96.png"
              alt="شرکت صحرا شرق کیمیا"
              style="width: 3rem"
            />
          </div>
        </div>
      </CSidebarBrand>
      <CSidebarHeader class="text-right bg-dark">
        <div class="y-center-g-10 text-black-50">
          <button
            class="increaseMony"
            @click="$router.push('userAccountManagement')"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
          <h6 v-if="inventoryState" class="text-white m-0" :class="{
          'out-of-balance':inventory < 3000
        }">
          <span class="font-sm">
            موجودی:
          </span>
            <i class="font-xl mr-2">{{ (inventory).toLocaleString() }}</i>
            <span class="font-sm">ریال</span>
          </h6>
          <h4 v-else class="faildInventry">
            {{ inventoryMessage }}
          </h4>
        </div>
      </CSidebarHeader>
      <hr class="position-relative ml-5 mx-3 mt-2 mb-3" style="border-color: #595959"/>
      <CRenderFunction flat :contentToRender="sidebarItems"/>
      <hr class="position-relative ml-5 mx-3 my-2" style="border-color: #222"/>
      <CSidebarHeader class="p-0 mb-2">
        <CButton
          color="primary"
          class="gap-15 border-0"
          @click="getAgentInfo()"
        >
          <CIcon name="cilPhone"></CIcon>
          <span>
            اطلاعات نماینده
          </span>
        </CButton>
      </CSidebarHeader>
    <VueModal
      title="اطلاعات نمایندگان مرتبط با زمین های شما"
      v-model="agentInfoModalState"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      :enable-close="true"
    >
      <AgentInfo/>
    </VueModal>
  </CSidebar>
</template>

<script>
import SidebarItems from "./SidebarItems";
import {mapActions, mapMutations, mapState} from "vuex";
import AgentInfo from "./AgentInfo";

export default {
  name: "TheSidebar",
  extends: SidebarItems,
  components: {
    AgentInfo,
  },
  data() {
    return {
      heightClass: "",
      inventory: 0,
      inventoryState: true,
      inventoryMessage: "",
      windowHeight: window.innerHeight,
      sidebarStyle: "",
      sidebarHeight: "",
      agentInfoModalState: false,
    };
  },
  computed: mapState({
    ...mapState({
      LoginedFullName: (state) =>
        state.login.type == null
          ? state.register.fullName == ""
            ? state.register.username
            : state.register.fullName
          : state.login.fullName == ""
            ? state.login.username
            : state.login.fullName,
    }),
    show: (state) => state.theme.sidebarShow,
    minimize: (state) => state.theme.sidebarMinimize,
  }),
  methods: {
    ...mapMutations(["theme"]),
    ...mapActions("financialUser", ["UserInventory"]),
    async getAgentInfo() {
      this.agentInfoModalState = true;
    },
    doToggleSidebarMinimize() {
      this.$store.commit("theme/toggle", "sidebarMinimize");
    },
    doSetsidebarShow(value) {
      this.$store.commit("theme/set", ["sidebarShow", value]);
    },
    async getCurrentUserFinancialAccountInventory() {
      let result = await this.UserInventory({});
      this.inventoryState = result.succeeded;
      this.inventoryMessage = result.message;
      if (result.succeeded) {
        this.inventory = result.data.inventory;
      }
    },
  },
  mounted() {
    if (this.logedInUser === "Admin") {
      this.heightClass = "h-100";
    } else {
      this.heightClass = "";
    }
    if (this.userTypeName != "FarmUser")
      this.getCurrentUserFinancialAccountInventory();
  },
};
</script>
<style scoped>

.imageCover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/sidebarImage.jpg) !important;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(.3) blur(0px);
  z-index: 0;
}
</style>
<style>
.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #f3f3f3;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: var(--secondary-light);
  border-radius: 0 30px 30px 0;
}

.c-sidebar li a {
  border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
}

.c-sidebar li:hover > a {
  color: #fff !important;;
  background: var(--secondary) !important;
}
.c-sidebar-nav-dropdown-items{
  gap: 3px;
  display: flex;
  flex-direction: column;
  margin: 3px 0;
}
.c-sidebar-nav-dropdown-items li:hover > a {
  color: #fff !important;;
  background: var(--secondary-light) !important;
}

.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon {
  color: #f3f3f3;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon {
  color: #f3f3f3;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #f3f3f3;
}

.c-sidebar-nav.ps{
  padding: 0 10px 0 0;
  gap: 3px;
}
.increaseMony {
  border: none;
  font-size: 15px;
  width: 35px;
  height: 35px;
  color: #fff;
  background-color: var(--primary);
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.increaseMony {
  animation: zoomIn .3s;
}

.out-of-balance {
   color:#e74f4f;
}

.faildInventry {
  background-color: #e74f4f;
  color: #fff;
}

.c-sidebar .c-sidebar-header {
  background: transparent !important;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  border-radius: var(--border-radius);
  position: relative;
  margin: 0 10px;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: var(--primary);
}

.c-sidebar .c-sidebar-brand {
  background: transparent !important;
}
</style>

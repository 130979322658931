import { render, staticRenderFns } from "./UserPartner.vue?vue&type=template&id=34cc2cf7&scoped=true"
import script from "./UserPartner.vue?vue&type=script&lang=js"
export * from "./UserPartner.vue?vue&type=script&lang=js"
import style0 from "./UserPartner.vue?vue&type=style&index=0&id=34cc2cf7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34cc2cf7",
  null
  
)

export default component.exports
<script>
import { mapState } from "vuex";

export default {
  name: "SidebarItems",
  data() {
    return {
      //Common And Farmer
      clientFarmManagement: {
        _name: "CSidebarNavDropdown",
        dropdownMode: "closeInactive",
        name: "زمین های شما",
        icon: "cil-map",
        items: [
          {
            name: "لیست زمین ها",
            to: "/farms",
          },
          {
            name: "ثبت زمین با فایل KML",
            to: "/farmAddKML",
          },
          {
            name: "ثبت زمین با نقشه",
            to: "/farmAddMap",
          },
          {
            name: "ثبت زمین از آرشیو",
            to: "/farmAddArchive",
          },
          // {
          //   name: "همپوشانی های زمین ها",
          //   to: "/",
          // },
          // {
          //   name: "مقایسه نقشه ها",
          //   to: "/",
          // },
        ],
      },
      clientFarmAction: {
        _name: "CSidebarNavDropdown",
        dropdownMode: "closeInactive",
        name: "مدیریت زمین ها",
        icon: "cil-grain",
        items: [
          {
            name: "تنش ها",
            to: "/tension",
          },
          {
            name: "عملیات ",
            to: "/operation",
          },
          {
            name: "هزینه ها",
            to: "/cost",
          },
        ],
      },
      clientGeoDatabase: {
        _name: "CSidebarNavItem",
        name: "ژئودیتابیس",
        to: "/geoDataBase",
        icon: "cil-location-pin",
      },
      clientWaterRequirements: {
        _name: "CSidebarNavItem",
        name: "نیاز آبی",
        icon: "cil-drop",
      },
      clientFinancialManagement: {
        dropdownMode: "closeInactive",
        _name: "CSidebarNavDropdown",
        name: "مدیریت مالی",
        icon: "cil-money",
        items: [
          {
            name: "خرید خدمات ماهواره ای",
            to: "/buySentinelService",
          },
          {
            name: "شارژ کیف پول",
            to: "/userAccountManagement",
          },
          {
            name: "خدمات خریداری شده",
            to: "/farmShopReport",
          },
          {
            name: "گزارشات و تراکنش های مالی",
            to: "/financialUserReports",
          },
          {
            name: "جشنواره ها و کدهای تخفیف",
            to: "/financialDiscount",
          },
        ],
      },
      agentFinancialManagement: {
        dropdownMode: "closeInactive",
        _name: "CSidebarNavDropdown",
        name: "مدیریت مالی",
        icon: "cil-money",
        items: [
          {
            name: "خرید خدمات ماهواره ای",
            to: "/buySentinelService",
          },
          {
            name: "خدمات خریداری شده",
            to: "/farmShopReport",
          },
          {
            name: "گزارشات و تراکنش های مالی",
            to: "/financialUserReports",
          },
          {
            name: "جشنواره ها و کدهای تخفیف",
            to: "/financialDiscount",
          },
        ],
      },
      adviceClient: {
        _name: "CSidebarNavItem",
        name: "توصیه های کشاورزی",
        to: "/adviceClient",
        icon: "cil-education",
      },
      dietPlanClient: {
        _name: "CSidebarNavItem",
        name: "برنامه غذایی",
        to: "/dietPlanClient",
        icon: "cil-fastfood",
      },
      clientTiket: {
        _name: "CSidebarNavItem",
        name: "تیکت ها",
        to: "/tiket",
        icon: "cil-chat-bubble",
      },
      // report: {
      //   _name: "CSidebarNavDropdown",
      //   dropdownMode: "closeInactive",
      //   name: "گزارشات",
      //   icon: "cil-newspaper",
      //   items: [
      //     {
      //       name: "گزارش تاریخ های زمین ها",
      //       to: "/farmReport",
      //     },
      //     {
      //       name: "گزارش خرید خدمات ماهواره ای",
      //       to: "/buySentinelServiceReport",
      //     },
      //     {
      //       name: "گزارش شارژ کیف پول",
      //       to: "/financialAccountReport",
      //     },
      //   ],
      // },
      contactUs: {
        _name: "CSidebarNavItem",
        name: "تماس با ما",
        to: "/contactus",
        icon: "cil-phone",
      },
      aboutUs: {
        _name: "CSidebarNavItem",
        name: "درباره ما",
        to: "/aboutUs",
        icon: "cilInfo",
      }, 
      cooperationRequest: {
        _name: "CSidebarNavItem",
        name: "درخواست همکاری",
        icon: "cil-speech",
        to: "/cooperationRequest",
      },
      Correspondences: {
        _name: "CSidebarNavItem",
        name: "چت",
        icon: "cil-speech",
        to: "/correspondences",
      },
      // conFarmerLegalEntity: {
      //   _name: "CSidebarNavItem",
      //   to: "/conFarmerLegalEntity",
      //   name: "خدمات حقوقی",
      //   icon: "cil-industry",
      // },
      conFarmerAdvicer: {
        _name: "CSidebarNavDropdown",
        dropdownMode: "closeInactive",
        name: "متخصصان",
        icon: "cil-people",
        items: [ 
          {
            name: "مدیریت متخصصان",
            to: "/conFarmerAdvicerManagement",
          },
          {
            name: "زمین های متخصصان",
            to: "/conFarmerAdvicerFarms",
          },
          {
            name: "دستورالعمل های ثبت شده",
            to: "/conFarmerAdvicerTips",
          },
        ],
      },
      conFarmerFarmuser: {
        _name: "CSidebarNavDropdown",
        dropdownMode: "closeInactive",
        name: "کارمندان مزرعه",
        icon: "cil-people",
        items: [
          {
            name: "مدیرت کارمندان مزرعه",
            to: "/conFarmerFarmuserManagement",
          },
          {
            name: "زمین های کارمندان مزرعه",
            to: "/conFarmerFarmuserFarms",
          },
          {
            name: "دستورالعمل های ثبت شده",
            to: "/conFarmerFarmuserTips",
          },
        ],
      },
      helpQuestion: {
        _name: "CSidebarNavItem",
        name: "سوالات متداول",
        to: "/helpQuestion",
        icon: "cil-chat-bubble",
      },
      helpMove: {
        _name: "CSidebarNavItem",
        name: "راهنمای سامانه",
        to: "/helpMove",
        icon: "cilMovie",
      },

      //farmUser
      farmUserFarmList: {
        _name: "CSidebarNavItem",
        name: "کل زمین های شما",
        to: "/farms",
        icon: "cil-map",
      },
      farmUserFarmAction: {
        _name: "CSidebarNavDropdown",
        dropdownMode: "closeInactive",
        name: "مدیریت زمین ها",
        icon: "cil-grain",
        items: [
          {
            name: "تنش ها",
            to: "/tension",
          },
          {
            name: "عملیات ",
            to: "/operation",
          },
        ],
      },
      conFarmuserFarms: {
        _name: "CSidebarNavItem",
        name: "کارفرمایان / زمین ها",
        to: "/farmuserFarms",
        icon: "cil-group",
      },
      conFarmuserTips: {
        _name: "CSidebarNavItem",
        name: "دستورالعمل های زمین",
        to: "/farmuserTips",
        icon: "cil-spa",
      },

      //agnet
      agentFarmList: {
        _name: "CSidebarNavItem",
        name: "زمین های منطقه",
        icon: "cil-map",
        to: "/conAgentFarms",
      },
      agentAllTensions: {
        _name: "CSidebarNavItem",
        name: "تنش های زمین ها",
        icon: "cil-tags",
      },
      agentAllOperaions: {
        _name: "CSidebarNavItem",
        name: "مدیریت زمین ها",
        icon: "cil-graph",
      },
      conAgentFarmer: {
        _name: "CSidebarNavItem",
        name: "کشاورزان",
        to: "/conAgentFarmer",
        icon: "cil-money",
      },
      conAgentLegalEntity: {
        _name: "CSidebarNavItem",
        name: "سازمان ها",
        to: "/conAgentLegalEntity",
        icon: "cil-money",
      },
      conAgentAdvicer: {
        _name: "CSidebarNavItem",
        name: "متخصصان",
        to: "/conAgentAdvicer",
        icon: "cil-money",
      },
      conAgentFarmuser: {
        _name: "CSidebarNavItem",
        name: "کارمندان مزرعه",
        to: "/conAgentFarmuser",
        icon: "cil-money",
      },

      //advicer
      advicerFarmList: {
        _name: "CSidebarNavItem",
        name: "کل زمین های شما",
        icon: "cil-map",
        to: "/farms",
      },
      advicerFarmAction: {
        _name: "CSidebarNavDropdown",
        name: "عملیات ی زمین",
        icon: "cil-grain",
        items: [
          {
            name: "تنش ها",
            to: "/tension",
          },
          {
            name: "عملیات ",
            to: "/operation",
          },
        ],
      },
      conAdvicerFarms: {
        _name: "CSidebarNavItem",
        name: "کارفرمایان / زمین ها",
        to: "/advicerFarms",
        icon: "cil-group",
      },
      conAdvicerTips: {
        _name: "CSidebarNavItem",
        name: "دستورالعمل های زمین",
        to: "/advicerTips",
        icon: "cil-spa",
      },

      exit: {
        _name: "CSidebarNavItem",
        name: "خروج",
        to: "/pages/login",
        icon: "cil-exit-to-app",
      },
    };
  },

  computed: {
    ...mapState({
      LoginedFullName: (state) =>
        state.login.type == null
          ? state.register.fullName == ""
            ? state.register.username
            : state.register.fullName
          : state.login.fullName == ""
          ? state.login.username
          : state.login.fullName,

      DisplayFarmUser: (state) =>
        state.login.type == null ? state.register.type : state.login.type,
      FarmerType: (state) =>
        state.login.type == null
          ? state.register.user.moreInfo.farmerType
          : state.login.user.moreInfo.farmerType,

      UserId: (state) =>
        state.login.type == null
          ? state.register.user.userId
          : state.login.user.userId,
    }),

    BaseItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavItem",
              name: "صفحه اصلی",
              to: "/dashboard",
              icon: "cil-home",
              style: "background-color:red !important",
            },
          ],
        },
      ];
    },

    farmerSidebarItems() {
      let returnList = this.BaseItems;
      let selected = returnList[0];
      selected._children.push(this.clientFarmManagement);
      selected._children.push(this.clientFarmAction);
      selected._children.push(this.clientGeoDatabase);
      selected._children.push(this.clientFinancialManagement);
      selected._children.push(this.adviceClient);
      selected._children.push(this.dietPlanClient);
      selected._children.push(this.cooperationRequest);
      selected._children.push(this.conFarmerAdvicer);
      if (this.FarmerType == 2) selected._children.push(this.conFarmerFarmuser);
      // selected._children.push(this.Correspondences);
      selected._children.push(this.clientTiket);
      // selected._children.push(this.report);
      selected._children.push(this.helpQuestion);
      selected._children.push(this.helpMove);
      selected._children.push(this.aboutUs);
      selected._children.push(this.contactUs);
      return returnList;
    }, 

    farmuserSidebarItems() {
      let returnList = this.BaseItems;
      let selected = returnList[0];
      selected._children.push(this.farmUserFarmList);
      selected._children.push(this.farmUserFarmAction);
      selected._children.push(this.clientGeoDatabase);
      selected._children.push(this.adviceClient);
      selected._children.push(this.dietPlanClient);
      selected._children.push(this.cooperationRequest);
      selected._children.push(this.conFarmuserTips);
      selected._children.push(this.conFarmuserFarms);
      // selected._children.push(this.Correspondences);
      selected._children.push(this.clientTiket);
      // selected._children.push(this.report);
      selected._children.push(this.helpQuestion);
      selected._children.push(this.helpMove);
      selected._children.push(this.aboutUs);
      selected._children.push(this.contactUs);
      return returnList;
    },

    agencySidebarItems() {
      let returnList = this.BaseItems;
      let selected = returnList[0];
      selected._children.push(this.agentFarmList);
      selected._children.push(this.adviceClient);
      selected._children.push(this.dietPlanClient);
      selected._children.push(this.agentFinancialManagement);
      selected._children.push(this.conAgentFarmer);
      selected._children.push(this.conAgentLegalEntity);
      selected._children.push(this.conAgentAdvicer);
      selected._children.push(this.conAgentFarmuser);
      // selected._children.push(this.Correspondences);
      selected._children.push(this.clientTiket);
      // selected._children.push(this.report);
      selected._children.push(this.helpQuestion);
      selected._children.push(this.helpMove);
      selected._children.push(this.aboutUs);
      selected._children.push(this.contactUs);
      return returnList;
    },

    advicerSidebarItems() {
      let returnList = this.BaseItems;
      let selected = returnList[0];
      selected._children.push(this.advicerFarmList);
      // selected._children.push(this.advicerFarmAction);
      selected._children.push(this.clientGeoDatabase);
      selected._children.push(this.clientFinancialManagement);
      selected._children.push(this.adviceClient);
      selected._children.push(this.dietPlanClient);
      selected._children.push(this.cooperationRequest);
      selected._children.push(this.conAdvicerTips);
      selected._children.push(this.conAdvicerFarms);
      // selected._children.push(this.Correspondences);
      selected._children.push(this.clientTiket);
      // selected._children.push(this.report);
      selected._children.push(this.helpQuestion);
      selected._children.push(this.helpMove);
      selected._children.push(this.aboutUs);
      selected._children.push(this.contactUs);
      return returnList;
    },

    sidebarItems() {
      switch (
        this.DisplayFarmUser
          ? this.DisplayFarmUser.toLowerCase()
          : this.DisplayFarmUser
      ) {
        case "farmer":
          return this.farmerSidebarItems; 
        case "farmuser":
          return this.farmuserSidebarItems;
        case "agency":
          return this.agencySidebarItems;
        case "advicer":
          return this.advicerSidebarItems;
        default:
          return this.BaseItems;
      }
    },
  },
};
</script>
<style>
.navOne {
  color: blueviolet;
}

/* .adminManagementRole {
  background-color: #000281 !important;
  color: #fff !important;
} */
</style>

<template>
  <div>
    <vue-element-loading
      :active="loading"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="agentInfoBox" v-if="agentsInfo.length > 0">
      <div
        class="agentInfoItem"
        v-for="(item, index) of agentsInfo"
        :key="index"
      >
        <div class="itemBox" title="برای کپی شدن کلیک کنید">
          <h4 class="mb-0">کد نماینده:</h4>
          <h5 class="mb-0 chip bg-secondary text-white cursor-pointer" @click="copyToClipboard(item.code)">{{
              item.code
            }}</h5>
        </div>
        <hr class="my-1"/>
        <div class="itemBox">
          <h4>نام و نام خانوادگی:</h4>
          <h5>{{ item.fullName }}</h5>
        </div>
        <div class="itemBox">
          <h4>تلفن همراه:</h4>
          <h5>{{ item.phone }}</h5>
        </div>
        <div class="itemBox" v-if="!!item.tel">
          <h4>تلفن ثابت:</h4>
          <h5>{{ item.tel }}</h5>
        </div>
        <hr class="my-1" v-if="!!item.address"/>
        <p class="d-flex align-items-start gap-5 w-100 m-0" v-if="!!item.address">
          <CIcon name="cilMap"/>
          <span>آدرس:</span>
          {{ item.address }}
        </p>
      </div>
    </div>
    <div v-else-if="!loading">
      <CAlert color="danger" class="animate__animated animate__backInDown">
        نماینده ای یافت نشد . با واحد بازرگانی تماس بگیرید:
        <a href="tel:+985133823129" dir="ltr">051 338 23129</a
        >
      </CAlert>
    </div>
    <p class="agentDesc mt-4">
      <CIcon name="cilInfo"/>
      <span>      خدمات نماینده به بهره برداران کشاورزی</span>
      <br/>
      <br/>
      1- امکان تهیه محصولاتی که در برنامه های غذایی به آن ها اشاره شده است.
      <br/>
      2- استفاده از امکانات توصیه ای نمایندگان با توجه به توصیه های کشاورزی
      سامانه.
      <br/>
      3- درخواست بازدید از مزرعه.
    </p>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "AgentInfoForm",
  data() {
    return {
      loading: false,
      loadingText: "دریافت اطلاعات نمایندگان شما ...",
      agentsInfo: [],
    };
  },

  methods: {
    ...mapActions("dashboard", ["GetCurrentUserAgentInfo"]),
    ...mapActions("general", ["copyText"]),
    async copyToClipboard(value) {
      await this.copyText(value);
      this.$notify({
        title: "کپی شد",
        text: "",
        type: "success",
      });
    },
    async fetchData() {
      this.loading = true;
      let result = await this.GetCurrentUserAgentInfo({});
      if (result.succeeded) {
        this.agentsInfo = [];
        result.data.forEach((element) => {
          this.agentsInfo.push({
            code: element.code,
            fullName: element.fullName,
            phone: element.phone,
            tel: element.tel,
            address: element.address,
          });
        });
      } else {
        this.$emit("close");
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات   <br /><br />",
          type: "error",
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.agentDesc {

}

.agentInfoBox {
  width: 100%;
  overflow-y: auto;
}

.agentInfoBox::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
.agentInfoBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

/* Handle */
.agentInfoBox::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}

/* Handle on hover */
.agentInfoBox::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}

.agentInfoItem {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  display: grid;
  padding: 15px 25px;
  width: 100%;
  gap: 8px;
}

.itemBox {
  display: flex;
  align-items: center;
}

.itemBox h4 {
  font-size: 1em;
  flex-basis: auto;
  flex-grow: 1;
}

.itemBox h5 {
  font-size: 1em;
}
</style>

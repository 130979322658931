<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <TheSidebar />
    <CWrapper>
      <TheHeader ref="headerRef" />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid class="h-100">
            <transition name="fade" mode="out-in" class="h-100">
              <router-view :key="$route.path" class="h-100"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
<!--      <TheFooter />-->
    </CWrapper>
    <CModal
      :title="$store.state.theme.errorTitle"
      color="danger"
      :show.sync="$store.state.theme.displayError"
      :closeOnBackdrop="false"
    >
      <h5>
        {{ $store.state.theme.errorText.errorText }}
      </h5>
      <template #footer>
        <button @click="$store.state.theme.displayError = false" class="bg-danger">
          متوجه شدم
        </button>
      </template>
    </CModal>
    <VueModal
      :title="modalTitle"
      v-model="HomePageModalShow"
      :enableClose="false"
    >
      <ChangePassword
        @close="closeModel"
        v-if="currentComponent === 'ChangePassword'"
      />
      <FarmerProfile
        @close="closeModel"
        v-if="currentComponent === 'FarmerProfile'"
      />
      <LegalEntityProfile
        @close="closeModel"
        v-if="currentComponent === 'LegalEntityProfile'"
      />
      <AgentProfile
        @close="closeModel"
        v-if="currentComponent === 'AgentProfile'"
      />
      <AdvicerProfile
        @close="closeModel"
        v-if="currentComponent === 'AdvicerProfile'"
      />
      <FarmUserProfile
        @close="closeModel"
        v-if="currentComponent === 'FarmUserProfile'"
      />
    </VueModal>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
// import TheFooter from "./TheFooter";
import ChangePassword from "../views/pages/UpdatePassword";
import FarmerProfile from "../views/pages/FarmerProfile";
import LegalEntityProfile from "../views/pages/LegalEntityProfile";
import AgentProfile from "../views/pages/AgentProfile";
import AdvicerProfile from "../views/pages/AdvicerProfile";
import FarmUserProfile from "../views/pages/FarmUserProfile";

import { getCurrentUser } from "../utils/index";
export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    // TheFooter,
    ChangePassword,
    FarmerProfile,
    LegalEntityProfile,
    AgentProfile,
    AdvicerProfile,
    FarmUserProfile,
  },
  created() {
    this.$root.$refs.Container = this;
  },
  data() {
    return {
      modalTitle: "",
      HomePageModalShow: false,
      currentComponent: "",
    };
  },
  methods: {
    changeComponent(cmp) {
      if (cmp == "ChangePassword") {
        this.currentComponent = "ChangePassword";
        this.modalTitle = "ویرایش رمز عبور ";
        this.HomePageModalShow = true;
      } else if (cmp == "EditProfile") {
        this.modalTitle = "ویرایش اطلاعات پروفایل ";
        if (getCurrentUser().type == "Farmer") {
          this.currentComponent = "FarmerProfile";
        } else if (getCurrentUser().type == "LegalEntity") {
          this.currentComponent = "LegalEntityProfile";
        } else if (getCurrentUser().type == "Agency") {
          this.currentComponent = "AgentProfile";
        } else if (getCurrentUser().type == "Advicer") {
          this.currentComponent = "AdvicerProfile";
        } else if (getCurrentUser().type == "FarmUser") {
          this.currentComponent = "FarmUserProfile";
        } else {
          this.currentComponent = "";
          this.modalTitle = "نوع کاربری شما شناسایی نشد";
        }
        this.HomePageModalShow = true;
      } else {
        return;
      }
    },
    closeModel() {
      this.HomePageModalShow = false;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
